import { SupportedLocales } from '@/typings';

export const isDevelopEnv = () => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return false;
};

export const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

export const getDefaultLang = () => {
  let lang = SupportedLocales.en;
  if (
    getQueryVariable('lang') === SupportedLocales.zh ||
    navigator.language.includes(SupportedLocales.zh)
  ) {
    lang = SupportedLocales.zh;
  }
  return lang;
};

export const getDefaultLangFromURL = () => {
  let lang = '';
  if (getQueryVariable('lang') === SupportedLocales.zh) {
    lang = SupportedLocales.zh;
  } else if (getQueryVariable('lang') === SupportedLocales.en) {
    lang = SupportedLocales.en;
  }
  return lang;
};
