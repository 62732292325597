import { TRecruitItem } from '@/typings/recruit';

/**
 * 运营类的招聘数据
 */
const getOperationData = (type: 'zh' | 'en'): TRecruitItem[] => {
  const zh = [];
  const en = [];

  return {
    zh,
    en,
  }[type];
};

export default getOperationData;
