import { PageLoading } from '@ant-design/pro-layout';
import { t, Trans } from '@lingui/macro';
import { Carousel, Image, Typography } from 'antd';
import { useMeasure } from 'react-use';
import { useMediaQuery } from 'react-responsive';
import { carouselBaseItems } from '@/configs/info';
import styles from './index.module.less';

export default function AboutUs() {
  const [measureRef, { width }] = useMeasure();
  const isWideScreen = useMediaQuery({ minWidth: 800 });

  return (
    <div className={styles.aboutUs} id="about-us">
      <Typography.Title
        level={3}
        style={{ textAlign: 'center', marginBottom: '1em' }}
      >
        {t`home.about-us`}
      </Typography.Title>
      <div ref={measureRef as any}>
        <div>
          <Trans id="home.about-us-culture">
            <p>
              Kasma成立于2023年，是一家国际化、专注于大数据与人工智能核心技术的初创公司，核心团队来自分布式系统与数据库领域的科学家和行业专家，并深耕图计算/存储系统、机器学习系统等领域十余年，有着丰富的行业实践和落地经验。
            </p>
            <p color="#595959">
              在
              Kasma，公司架构扁平，团队致力于将技术创新落地到实际产品中，以给用户带来更大的价值。
            </p>
          </Trans>
        </div>
        <Carousel
          style={{ width: isWideScreen ? width / 2 : width }}
          dots={{ className: styles.dots }}
        >
          {carouselBaseItems.map((src) => (
            <Image
              style={{ minWidth: 320 }}
              key={src}
              src={src}
              placeholder={<PageLoading />}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}
