import type { RouteProps } from '@/typings';
import NotFound from '@/pages/NotFound';
import AppInfo from '@/pages/Other/AppInfo';
import Hello from '@/pages/Other/Hello';
import I18N from '@/pages/Other/I18N';
import Simple from '@/pages/Other/Simple';
import User from '@/pages/Other/User';
import Home from '@/pages/Home';
import Recruit from '@/pages/Recruit';
import RecruitDetail from '@/pages/Recruit/Detail';

const routes: RouteProps[] = [
  {
    element: <Home />,
    index: true,
  },
  {
    path: '/app-info',
    element: <AppInfo />,
  },
  {
    path: '/hello',
    element: <Hello />,
  },
  {
    path: '/user',
    element: <User />,
  },
  {
    path: '/simple',
    element: <Simple />,
  },
  {
    path: '/i18n',
    element: <I18N />,
  },
  {
    path: '/recruit',
    element: <Recruit />,
  },
  {
    path: '/recruit/:name',
    element: <RecruitDetail />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
