import QueueAnim from 'rc-queue-anim';
import styles from './index.module.less';

const RecruitTitle = ({ info }) => {
  return (
    <QueueAnim duration={1000} type="bottom">
      <section key="recruit-title" className={styles.title}>
        {info}
      </section>
    </QueueAnim>
  );
};

export default RecruitTitle;
