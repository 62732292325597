///**
// * 开发的招聘数据
// */
import {
  TRecruitItem,
  RecruitType,
  RecruitBaseType,
  RecruitProjectType,
} from '@/typings/recruit';

const getRADData = (type: 'zh' | 'en'): TRecruitItem[] => {
  const zh = [
    {
      name: `数据库存储引擎研发`,
      type: [RecruitType.CAMPUS, RecruitType.INTERN],
      base: [
        RecruitBaseType.BEIJING,
        RecruitBaseType.SHENZHEN,
        RecruitBaseType.HONGKONG,
      ],
      duty: [
        `参与Graxy分布式分析型图数据库的设计与开发工作；`,
        `负责查询引擎、存储引擎、元数据模板的设计与开发工作。`,
        `关于图数据库的一些基础知识可以參考我们最近在DataFun的分享: https://mp.weixin.qq.com/s/8MHQT6J-ah3KElx5ZtmHwA`,
      ],
      requirement: [
        `熟练使用C/C++, Rust, Go 任一编程语言，有良好的代码习惯；`,
        `对分布式系统架构，分布式存储方向充满热情；`,
        `熟悉计算机系统结构，熟悉多线程、网络编程等技术优先；`,
        `优秀的发现和解决问题能力，良好的沟通能力，快速学习能力。`,
      ],
      extra: [
        `有数据库、分布式系统研发经验者优先；`,
        `熟悉Doris/ClickHouse/Druid/Kylin等OLAP系统原理者优先；`,
        `熟悉Impala/Presto/Hive/Spark/Hadoop等分布式计算引擎原理者优先；`,
        `熟悉HDFS/Cassandra/HBase/Kudu/RocksDB等存储引擎原理者优先。`,
      ],
    },
    {
      name: `图计算分析引擎研发`,
      base: [
        RecruitBaseType.BEIJING,
        RecruitBaseType.SHENZHEN,
        RecruitBaseType.HONGKONG,
      ],
      type: [RecruitType.CAMPUS, RecruitType.INTERN],
      duty: [`参与Graxy分布式图计算/挖掘系统的设计与开发工作。`],
      requirement: [
        `精通C/C++，深入了解高并发编程、网络编程原理；`,
        `对分布式系统架构方向充满热情；`,
        `了解并行计算，具有OpenMP, MPI 相关开发经验；`,
        `优秀的发现和解决问题能力，良好的沟通能力，快速学习能力。`,
      ],
      extra: [
        `了解主流的图分析/挖掘算法理论，并有相关的编程经验；`,
        `熟悉分布式图计算框架（e.g., Pregel, GraphLab）原理，了解业界主流图计算系统（Giraph, GraphX, Plato, Grape, etc.）；`,
        `对图计算在业务场景（e.g., 风控，审核，搜索/广告/推荐）上的应用有工作经验者优先。`,
      ],
    },
    {
      name: `图AI训练/推理引擎研发`,
      base: [
        RecruitBaseType.BEIJING,
        RecruitBaseType.SHENZHEN,
        RecruitBaseType.HONGKONG,
      ],
      type: [RecruitType.CAMPUS, RecruitType.INTERN],
      duty: [
        `参与Graxy分布式图神经网络训练/推理系统的设计与开发工作；负责GNN算法的开发工作。`,
      ],
      requirement: [
        `熟练使用Python, C/C++ 两种编程语言，有良好的代码习惯；`,
        `熟悉分布式Tensorflow、Pytorch下的编程；熟悉GNN算法基本知识；`,
        `对分布式系统架构，分布式存储方向充满热情；`,
        `优秀的发现和解决问题能力，良好的沟通能力，快速学习能力。`,
      ],
      extra: [
        `熟悉业界开源的GNN系统（e.g., DGL, PyG, PGL, AliGraph, etc.）；`,
        `熟悉HDFS/Hive/Spark/KafKa/Arrow等大数据系统；`,
        `在GNN系统/算法领域发表过计算机顶会论文。`,
      ],
    },
    {
      name: `后台开发方向`,
      base: [
        RecruitBaseType.BEIJING,
        RecruitBaseType.SHENZHEN,
        RecruitBaseType.HONGKONG,
      ],
      type: [RecruitType.CAMPUS, RecruitType.INTERN],
      duty: [
        `参与服务后台的架构设计、开发、优化，打造更稳定、高效和安全可靠的后台系统。`,
      ],
      requirement: [
        `扎实的编程能力；`,
        `熟练掌握C++/Go/Rust等其中一门开发语言；`,
        `熟悉TCP/UDP网络协议及相关编程、进程间通信编程；`,
        `专业软件知识，包括算法、操作系统、软件工程、设计模式、数据结构、数据库系统、网络安全等。`,
      ],
      extra: [
        `分布式系统设计与开发，负载均衡、系统容灾、高可用系统等知识；`,
        `对云原生相关技术有所了解；`,
        `加分项：有图数据库如Neo4j等、图计算系统如Spark等开发经验。`,
      ],
    },
    {
      name: `全栈开发方向`,
      base: [
        RecruitBaseType.BEIJING,
        RecruitBaseType.SHENZHEN,
        RecruitBaseType.HONGKONG,
      ],
      type: [RecruitType.CAMPUS, RecruitType.INTERN],
      duty: [`参与产品的前后端开发，打造业界最好用的图产品；`],
      requirement: [
        `熟练掌握React/Vue等前端技术；`,
        `熟悉java/C++/Python/Go等主流编程语言，接触过相应的框架并有一定的理解；`,
        `熟练使用MySQL/MongoDB等数据库；`,
        `有较强的学习能力、分析及解决问题能力，具备良好的团队合作意识，心态好，有责任心。`,
      ],
      extra: [
        `了解Redis/MemCache等缓存中间件优先；`,
        `有性能优化方面的经验优先；`,
        `有互联网实习项目经验者优先。`,
      ],
    },
    {
      name: `Ofnil 系统开发`,
      base: [RecruitBaseType.HONGKONG],
      type: [RecruitType.CAMPUS, RecruitType.INTERN],
      project: RecruitProjectType.OFNIL,
      summary: `我们正在寻找具有优秀的编程技能和对开源 MLOps 系统热衷的系统开发方向工程师和实习生。`,
      duty: [
        `参与开发一个使用 Rust 语言编写的 feature platform；`,
        `研究 feature platform 的上下游系统，为我们的 Ofnil graph feature platform 提供支持；`,
        `备注：对于实习生, 在实习结束时，我们希望实习生能够充分理解特征工程或服务管道，解释关键的想法和设计选择，并以务实的、面向开源的方式有效地实现这些功能。`,
      ],
      requirement: [
        `至少可工作 6 个月，时间较长者为佳；`,
        `在使用 Rust、C++ 和 Python 等语言编程方面有出色的经验；`,
        `良好的人际交往能力和流利的英语；`,
      ],
      extra: [
        `接受过计算机科学方面的研究生教育，最好是系统研究或数据库研究方面研究生。`,
        `开源贡献经验。`,
        `有云技术栈的经验，如使用 AWS、Kubernetes 和 Docker 容器等。`,
        `有系统开发、数据库、数据流系统和云计算的经验。`,
        `熟悉 protobuf、gRPC、Restful API 等工具和协议。`,
        `CI-CD 知识。`,
      ],
    },
  ];
  const en = [
    {
      name: `Database Storage R&D`,
      type: [RecruitType.CAMPUS, RecruitType.INTERN],
      duty: [
        `Engage in the design and development of a distributed graph database, including query engine, storage engine, and/or metadata templates`,
        `Collaborate with cross-functional teams to optimize system performance and scalability`,
      ],
      requirement: [
        `Proficiency in using C/C++, Rust, or Go with excellent coding practices`,
        `Passion for R&D of distributed computing systems and distributed storage systems`,
        `Familiarity with computer system architecture, multi-threading, network programming, and related techniques`,
        `Excellent problem solving skills and good communication skills with a strong ability to learn quickly`,
      ],
      extra: [
        `Experience in database and distributed system development`,
        `Familiarity with principles of OLAP systems such as Doris/ClickHouse/Druid/Kylin`,
        `Familiarity with distributed computing engines such as Impala/Presto/Hive/Spark/Hadoop`,
        `Familiarity with storage engines such as HDFS/Cassandra/HBase/Kudu/RocksDB`,
      ],
    },
    {
      name: `Graph Computing/Analytics System R&D`,
      type: [RecruitType.CAMPUS, RecruitType.INTERN],
      duty: [
        `Participate in the design and development of high-performance distributed graph computing/mining systems`,
        `Collaborate with cross-functional teams to optimize system performance and scalability`,
      ],
      requirement: [
        `Proficiency in C/C++ programming with excellent coding practices`,
        `Strong experience in high-concurrency programming and network programming`,
        `Passion for distributed system design and development`,
        `Solid understanding of parallel computing with experience in OpenMP and MPI`,
        `Excellent problem solving skills and good communication skills with a strong ability to learn quickly`,
      ],
      extra: [
        `Familiarity with mainstream graph analytics and graph mining algorithms`,
        `Good knowledge of distributed graph computing frameworks (e.g., Pregel, GraphLab) and experience in using popular graph computing systems in the industry (e.g., Giraph, GraphX, Plato, Grape)`,
        `Experience in applying graph computing in real business scenarios (e.g., risk management, search/advertising/recommendation)`,
      ],
    },
    {
      name: `Graph AI System R&D`,
      type: [RecruitType.CAMPUS, RecruitType.INTERN],
      duty: [
        `Participate in the design and development of a distributed graph neural network training/inference system`,
        `Responsible for the implementation of GNN algorithms and applications`,
        `Collaborate with cross-functional teams to optimize system/algorithm performance and scalability`,
      ],
      requirement: [
        `Proficiency in Python and C/C++ with excellent coding practices`,
        `Experience in programming in distributed Tensorflow and Pytorch`,
        `Experience in implementing common GNN algorithms`,
        `Excellent problem solving skills and good communication skills with a strong ability to learn quickly`,
      ],
      extra: [
        `Familiarity with popular GNN systems such as DGL, PyG, PGL and AliGraph`,
        `Familiarity with big data systems such as HDFS, Hive, Spark, Kafka and Arrow`,
      ],
    },
    {
      name: `Backend System R&D`,
      type: [RecruitType.CAMPUS, RecruitType.INTERN],
      duty: [
        `Participate in the architecture design, development, and optimization of a backend system to provide robust, efficient, and secure backend services`,
        `Collaborate with cross-functional teams to optimize system performance and scalability`,
      ],
      requirement: [
        `Solid programming skills and proficiency in one of the following development languages, C++, Java, Go, Rust, with excellent coding practices`,
        `Familiarity with TCP/UDP network protocols and IPC`,
        `Excellent knowledge in operating systems, software engineering, database systems, network security, data structure and algorithm design`,
        `Excellent problem solving skills and good communication skills with a strong ability to learn quickly`,
      ],
      extra: [
        `Knowledge of distributed system design and development, load balancing, system resilience, high availability, and related concepts`,
        `Familiarity with cloud-native related technologies`,
        `Familiarity with graph databases (e.g., Neo4j, TigerGraph) and graph computing systems (e.g., Giraph, GraphX, Plato, Grape)`,
      ],
    },
    {
      name: `Full-Stack Development`,
      type: [RecruitType.CAMPUS, RecruitType.INTERN],
      duty: [
        `Participate in the development of front-end and back-end for our products`,
        `Collaborate with cross-functional teams and customers to improve our products`,
      ],
      requirement: [
        `Proficiency in front-end technologies such as React/Vue`,
        `Familiarity with mainstream programming languages such as Java, C++, Python and Go`,
        `Proficiency in using databases such as MySQL/MongoDB`,
        `Strong learning ability, analytical and problem-solving skills, good teamwork, responsible and reliable`,
      ],
      extra: [
        `Knowledge of cache systems such as Redis/MemCache`,
        `Experience in front-end performance optimization`,
        `Intern experience in top internet companies`,
      ],
    },
  ];
  return {
    zh,
    en,
  }[type];
};

export default getRADData;
