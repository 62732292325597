const defaultSettings = {
  layout: 'top',
  title: 'Kasma',
  primaryColor: '#3875f6',
  contentWidth: 'Fixed',
  fixSiderbar: true,
  colorPrimary: '#1677FF',
  splitMenus: false,
  fixedHeader: false,
};

export default defaultSettings;
