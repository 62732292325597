import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import ProBasicLayout, {
  SettingDrawer,
  getMenuData,
  MenuDataItem,
  SettingDrawerProps,
} from '@ant-design/pro-layout';
import { Dropdown, MenuProps } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { useLocation, useNavigate } from '@/hooks';
import { isDevelopEnv, getDefaultLangFromURL } from '@/utils';
import { useMenus, menuIcon, config, languages, language } from '@/configs';
import defaultSettings from '@/defaultSettings';
import { ConfigContext } from '@/utils/context';
import { scrollToAnchor } from '@/utils/scroll';
import styles from './index.module.less';

const renderMenuItem = (menus: MenuDataItem[]): MenuDataItem[] =>
  menus.map(({ icon, children, ...item }) => ({
    ...item,
    icon: icon && menuIcon[icon as string],
    children: children && renderMenuItem(children),
  }));

const BasicLayout: React.FC = (props) => {
  const [scrollTop, setScrollTop] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [settings, setSetting] = useState(
    defaultSettings as SettingDrawerProps['settings']
  );
  const { language: locale, setLanguage } = useContext(ConfigContext);
  const currentLanguage = language[locale];
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>(['/']);
  const isDev = isDevelopEnv();
  const { pathname, hash } = location;
  const menus = useMenus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { breadcrumbMap, menuData } = useMemo(() => getMenuData(menus), []);

  useEffect(() => {
    const onScroll = () => setScrollTop(document.documentElement.scrollTop);
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    const select = breadcrumbMap.get(pathname);
    if (select) {
      setOpenKeys((select as MenuDataItem)['pro_layout_parentKeys']);
      setSelectedKeys([(select as MenuDataItem)['key'] as string]);
    }
  }, [breadcrumbMap, pathname]);

  const menuDataRender = useCallback(
    () => renderMenuItem(menuData),
    [menuData]
  );

  useEffect(() => {
    const [, anchor] = hash.split('#');

    if (hash) {
      const anchorElement = document.getElementById(anchor);
      if (anchorElement) {
        scrollToAnchor(anchor);
        return;
      }
    }

    setTimeout(() => {
      scrollToAnchor(anchor);
    }, 600);
  }, [hash]);

  const menuItemRender = useCallback((menuItemProps, defaultDom) => {
    if (menuItemProps.isUrl || !menuItemProps.path) {
      return defaultDom;
    }
    const url = new URL(menuItemProps.path, window.location.origin);
    const urlLang = searchParams.get('lang');
    if (urlLang) {
      url.searchParams.set('lang', urlLang);
    }

    return <Link to={url.pathname + url.search + url.hash}>{defaultDom}</Link>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnOpenChange = useCallback(
    (keys: string[]) => setOpenKeys(keys as string[]),
    []
  );

  const menuProps = useMemo<MenuProps>(
    () => ({
      selectedKeys,
      openKeys,
      onOpenChange: handleOnOpenChange,
    }),
    [handleOnOpenChange, openKeys, selectedKeys]
  );

  const rightContentRender = () => (
    <Dropdown
      menu={{
        items: languages.map((item) => ({
          key: item.key,
          onClick: () => {
            if (getDefaultLangFromURL()) {
              searchParams.set('lang', item.key);
              setSearchParams(searchParams);
            }
            setLanguage(item.key);
          },
          label: item.value,
        })),
      }}
    >
      <div className={styles.headerButton}>{currentLanguage.value}</div>
    </Dropdown>
  );

  return (
    <>
      <ProBasicLayout
        {...settings}
        title={''}
        logo={
          <span
            onClick={() => {
              navigate('/#home');
            }}
            style={{
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src={config.logo} alt="" />
          </span>
        }
        menuDataRender={menuDataRender}
        menuItemRender={menuItemRender}
        menuProps={menuProps}
        pageTitleRender={() => {
          if (locale === 'zh') {
            return 'Kasma - 赋能企业数据创新与人工智能';
          }
          return 'Kasma - Empowering Enterprises with Data Innovation and AI';
        }}
        rightContentRender={rightContentRender}
        contentStyle={{
          padding: 0,
        }}
        fixedHeader
        token={{
          bgLayout: '#fff',
          header: {
            colorTextMenu: '#fff',
            colorBgHeader:
              scrollTop < 520 - 56 ? 'transparent' : 'rgba(4, 12, 65, 0.8)',
          },
        }}
      >
        {props.children}
      </ProBasicLayout>

      {isDev && (
        <SettingDrawer
          getContainer={() => document.getElementById('root')}
          settings={settings}
          onSettingChange={setSetting}
          enableDarkTheme
        />
      )}
    </>
  );
};

export default BasicLayout;
