import { TConfig } from '@/typings';
import { getDefaultLang } from '@/utils';

export const config: TConfig = {
  title: 'Kasma',
  logo: 'logo.svg',
  language: getDefaultLang(),
};

export const { REACT_APP_AMAP_KEY, REACT_APP_ASSETS_URL } = process.env;
