import {
  RecruitBaseType,
  RecruitJobType,
  RecruitOptionsType,
  RecruitProjectType,
  RecruitType,
  TRecruitItem,
} from '@/typings/recruit';
import { RecruitOptionsKeyType } from '@/pages/Recruit/Options';
import getRADData from './data/recruit/researchDevelopment';
import getDesignData from './data/recruit/design';
import getOperationData from './data/recruit/operation';
import getProductData from './data/recruit/product';

// 因数据由岗位类型分类到不同文件，在各自文件加job类型有点多余，因此在此处为招聘数据加上岗位类型
const handleSetJob = (recruitList, type: RecruitJobType, prefix) =>
  recruitList.map((item, idx) => ({
    ...item,
    job: type,
    id: `${prefix}_${idx}`,
  }));

const includeFilter =
  (searchkey, prop: keyof RecruitOptionsType) => (databaseItem) => {
    if (!searchkey || searchkey.length === 0) {
      return true;
    }
    if (
      databaseItem &&
      databaseItem[prop] &&
      searchkey.includes(databaseItem[prop])
    ) {
      return true;
    }
    return false;
  };

const isArrEqualFilter =
  (searchArr, prop: keyof RecruitOptionsType) => (databaseItem) => {
    if (!searchArr || searchArr.length === 0) {
      return true;
    }
    if (databaseItem && databaseItem[prop]) {
      return searchArr.some((keyword) => databaseItem[prop].includes(keyword));
    }
    return false;
  };

/**
 * 根据name type project base 获取招聘岗位列表
 */
export type GetRecruitListProps = Partial<{
  job: string[];
  type: RecruitType[];
  base: RecruitBaseType[];
  project: RecruitProjectType[];
  offset: number;
  limit: number;
  languages: 'zh' | 'en';
}>;

const getList = (languages: 'zh' | 'en'): TRecruitItem[] => [
  ...handleSetJob(getRADData(languages), RecruitJobType.RAD, 'rad'),
  ...handleSetJob(getDesignData(languages), RecruitJobType.DESIGN, 'design'),
  ...handleSetJob(
    getOperationData(languages),
    RecruitJobType.OPERATION,
    'operation'
  ),
  ...handleSetJob(getProductData(languages), RecruitJobType.PRODUCT, 'product'),
];

export async function getRecruitList({
  type,
  job,
  project,
  base,
  offset = 0,
  limit = 10,
  languages = 'zh',
}: GetRecruitListProps) {
  const projectFilter = includeFilter(project, 'project');
  const jobFilter = includeFilter(job, 'job');
  const typeFilter = isArrEqualFilter(type, 'type');
  const baseFilter = isArrEqualFilter(base, 'base');

  const list = getList(languages)
    .filter(jobFilter)
    .filter(projectFilter)
    .filter(typeFilter)
    .filter(baseFilter);

  return {
    code: 0,
    total: list.length,
    data: {
      list: list.slice(offset).slice(0, limit),
    },
    has_more: offset + limit < list.length,
  };
}

/**
 * 根据name查找具体岗位
 */
export const getRecruitDetail = async (id: string, languages: 'zh' | 'en') =>
  getList(languages).find((item) => {
    return id === item.id;
  });

const getListFromEnum = (enumData) => {
  const keys: string[] = [];
  for (let key in enumData) {
    keys.push(enumData[key]);
  }
  return keys;
};
/**
 * 查找招聘条件
 * @returns
 */
export const getRecruitOptions = async () => {
  const jobList = getListFromEnum(RecruitJobType);
  const typeList = getListFromEnum(RecruitType);
  const projectList = getListFromEnum(RecruitProjectType);
  const baseList = getListFromEnum(RecruitBaseType);

  const list = [
    {
      name: '岗位职能',
      type: RecruitOptionsKeyType.JOB,
      options: jobList,
    },
    {
      name: '招聘类型',
      type: RecruitOptionsKeyType.TYPE,
      options: typeList,
    },
    {
      name: '项目类别',
      type: RecruitOptionsKeyType.PROJECT,
      options: projectList,
    },
    {
      name: '工作地点',
      type: RecruitOptionsKeyType.BASE,
      options: baseList,
    },
  ];

  return {
    code: 0,
    data: list,
  };
};
