import QueueAnim from 'rc-queue-anim';
import { OverPack } from 'rc-scroll-anim';
import {
  AtomOutlined,
  CultureOutlined,
  MissionOutlined,
} from '@/components/Icon';
import styles from './index.module.less';
import { t } from '@lingui/macro';

export default function Culture() {
  const culture = [
    {
      name: t`home.culture-vision`,
      icon: <AtomOutlined />,
      content: t`home.culture-vision-desc`,
    },
    {
      name: t`home.culture-mission`,
      icon: <MissionOutlined />,
      content: t`home.culture-mission-desc`,
    },
    {
      name: t`home.culture-culture`,
      icon: <CultureOutlined />,
      content: t`home.culture-culture-desc`,
    },
  ];
  return (
    <OverPack playScale={0.15}>
      <QueueAnim
        key="culture"
        leaveReverse
        type="bottom"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
          gap: '3em',
          alignItems: 'start',
        }}
      >
        {culture.map(({ name, icon, content }) => (
          <section key={name} className={styles.cultureItem}>
            {icon}
            <header>{name}</header>
            <section>{content}</section>
          </section>
        ))}
      </QueueAnim>
    </OverPack>
  );
}
