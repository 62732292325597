import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { I18nProvider } from '@lingui/react';
import { Layout } from '@/components';
import routes from '@/routes';
import { useIntlProvider } from '@/hooks';
import { ConfigContext } from '@/utils/context';
import { ConfigProvider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 24 }} spin />);

const RootRouter = () => {
  const { language, setLanguage, i18n, loading, locale } = useIntlProvider();
  const globalConfig = {
    language,
    setLanguage,
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: '100%',
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <ConfigContext.Provider value={globalConfig}>
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={true}>
        <ConfigProvider locale={locale}>
          <BrowserRouter>
            <Layout>
              <Routes>
                {routes.map((route, index: number) => (
                  <Route key={index} {...route} element={route.element} />
                ))}
              </Routes>
            </Layout>
          </BrowserRouter>
        </ConfigProvider>
      </I18nProvider>
    </ConfigContext.Provider>
  );
};

export default RootRouter;
