import { TRecruitItem } from '@/typings/recruit';

/**
 * 产品类的招聘数据
 */
const getProductData = (type: 'zh' | 'en'): TRecruitItem[] => {
  const zh = [];
  const en = [];

  return {
    zh,
    en,
  }[type];
};

export default getProductData;
