import Icon from '@ant-design/icons';
import classnames from 'classnames';
import styles from './index.module.less';
import { ReactComponent as AtomSVG } from './svg/atom.svg';
import { ReactComponent as CultureSVG } from './svg/culture.svg';
import { ReactComponent as MissionSVG } from './svg/mission.svg';
import { ReactComponent as TaskFoldSVG } from './svg/taskFold.svg';
import { ReactComponent as LocationSVG } from './svg/location.svg';
type IconProps = React.ComponentProps<typeof Icon>;

export const AtomOutlined = (props: IconProps) => (
  <Icon component={AtomSVG} {...props} />
);

export const MissionOutlined = (props: IconProps) => (
  <Icon component={MissionSVG} {...props} />
);

export const CultureOutlined = (props: IconProps) => (
  <Icon component={CultureSVG} {...props} />
);

interface LoadingOutlinedProps extends IconProps {
  iconStyle?: IconProps['style'];
}

export const LoadingOutlined = (props: LoadingOutlinedProps) => {
  const { iconStyle, ...other } = props;
  return (
    <Icon
      component={() => (
        <div className={styles.loadingContainer}>
          <div
            style={iconStyle}
            className={classnames(styles.loading, 'loading-outlined')}
          />
        </div>
      )}
      {...other}
    />
  );
};

export const TaskFoldFilled = (props: IconProps) => (
  <Icon component={TaskFoldSVG} {...props} />
);

export const LocationOutlined = (props: IconProps) => (
  <Icon component={LocationSVG} {...props} />
);
