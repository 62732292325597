import { GetRecruitListProps } from '@/server';

export enum RecruitJobType {
  RAD = 'R&D',
  PRODUCT = 'product',
  OPERATION = 'operation',
  DESIGN = 'design',
}

export enum RecruitProjectType {
  OFNIL = 'Ofnil',
  GRAXY = 'Graxy',
}
export enum RecruitType {
  CAMPUS = '校招',
  INTERN = '实习',
  SOCIAL = '社招',
}
export enum RecruitBaseType {
  SHENZHEN = '深圳',
  BEIJING = '北京',
  HONGKONG = '香港',
}
export type RecruitOptionsType = Pick<
  GetRecruitListProps,
  'base' | 'job' | 'project' | 'type'
>;
export type TRecruitItem = Partial<{
  id: string;
  name: string; // 项目名
  job: RecruitJobType; // 岗位类型
  project: RecruitProjectType; // 项目
  type: RecruitType[]; // 招聘类型 校招or实习or社招
  base: RecruitBaseType[]; // base
  summary: string; // 引言 / 概要
  duty: string[]; // 岗位职责
  requirement: string[]; // 招聘要求
  extra: string[]; // 加分项
  workMode: string; // 工作模式
}>;
