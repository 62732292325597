import { t } from '@lingui/macro';
import { REACT_APP_ASSETS_URL } from '../default';

const setBaseUrl = (src: string) => `${REACT_APP_ASSETS_URL}/${src}`;

export const carouselBaseItems = [
  'shenzhen/Shenzhen-5.jpg',
  'beijing/BeiJing-11.jpg',
  'beijing/BeiJing-12.jpg',
  'hk/HK-16.jpg',
  'hk/HK-18.jpg',
].map(setBaseUrl);

export const useBaseInfo = () => {
  return [
    {
      name: t`中国香港新界大埔区白石角科学园`,
      img: 'hk/HK-19.jpg',
      base: t`中国 · 香港`,
    },
    {
      name: t`深圳市南山区华润置地大厦C座`,
      img: 'shenzhen/Shenzhen-1.jpg',
      base: t`中国 · 深圳`,
    },
    {
      name: t`北京市海淀区成府路150号智源大厦`,
      img: 'beijing/BeiJing-14.jpg',
      base: t`中国 · 北京`,
    },
  ].map((item) => ({ ...item, img: setBaseUrl(item.img) }));
};

export const useTeamInfo = () => {
  return [
    {
      name: t`Professor James Cheng`,
      title: t`联合创始人`,
      avatar: 'avatar/Avatar-21.jpg',
      intro: t({
        id: 'CoreTeam.JamesCheng.intro',
        message:
          '香港中文大学计算机科学与工程系 Husky Data Lab 的主任，长期从事分布式系统、分布式计算、图计算与图数据管理、机器学习系统等方向的研究工作',
      }),
    },
    {
      name: t`Albert Lee`,
      title: t`联合创始人 & CEO`,
      avatar: 'avatar/Avatar-20.jpg',
      intro: t({
        id: 'CoreTeam.AlbertLee.intro',
        message:
          '大数据领域专家/算法专家，曾就职于腾讯，长期从事分布式计算/存储系统研究，熟悉图计算、图存储系统，对图技术在各行业的应用有丰富的经验 & 实践经历',
      }),
    },
    {
      name: t`Jimmy Chan`,
      title: 'Co-founder & CTO',
      avatar: 'avatar/Avatar-20.jpg',
      intro: t({
        id: 'CoreTeam.JimmyChan.intro',
        message:
          '资深基础软件工程师/架构师，曾就职于字节跳动、微软亚洲研究院，擅长分布式计算和存储系统，在图数据库、图AI系统、图计算系统等领域有8年研究 & 工作经验',
      }),
    },
  ].map((item) => ({ ...item, avatar: setBaseUrl(item.avatar) }));
};
