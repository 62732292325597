import BgContainer from '@/components/BgContainer';
import { Empty } from 'antd';

const NotFound = () => {
  return (
    <BgContainer
      header={
        <section
          style={{ fontSize: '6em', fontWeight: 'bold', textAlign: 'center' }}
        >
          404
        </section>
      }
    >
      <Empty />
    </BgContainer>
  );
};

export default NotFound;
