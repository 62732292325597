import {
  RecruitBaseType,
  RecruitJobType,
  RecruitProjectType,
  RecruitType,
} from '@/typings/recruit';
import { RecruitOptionsKeyType } from '@/pages/Recruit/Options';
import { t } from '@lingui/macro';

export const useRecruitMap = () => {
  const RecruitJobTypeName = {
    [RecruitJobType.RAD]: t`研发`,
    [RecruitJobType.PRODUCT]: t`产品`,
    [RecruitJobType.OPERATION]: t`运营`,
    [RecruitJobType.DESIGN]: t`设计`,
  };

  const RecruitTypeName = {
    [RecruitType.CAMPUS]: t`校招`,
    [RecruitType.INTERN]: t`实习`,
    [RecruitType.SOCIAL]: t`社招`,
  };

  const RecruitProjectTypeName = {
    [RecruitProjectType.OFNIL]: t`Ofnil`,
    [RecruitProjectType.GRAXY]: t`Graxy`,
  };

  const RecruitBaseTypeName = {
    [RecruitBaseType.SHENZHEN]: t`深圳`,
    [RecruitBaseType.BEIJING]: t`北京`,
    [RecruitBaseType.HONGKONG]: t`香港`,
  };

  const RecruitOptionTypeName = {
    [RecruitOptionsKeyType.BASE]: t`工作地点`,
    [RecruitOptionsKeyType.JOB]: t`岗位职能`,
    [RecruitOptionsKeyType.PROJECT]: t`项目类别`,
    [RecruitOptionsKeyType.TYPE]: t`招聘类型`,
  };

  return {
    RecruitJobTypeName,
    RecruitTypeName,
    RecruitProjectTypeName,
    RecruitBaseTypeName,
    RecruitOptionTypeName,
  };
};
