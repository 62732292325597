import { useContext } from 'react';
import AMapLoader from '@amap/amap-jsapi-loader';
import { useEffect } from 'react';
import { REACT_APP_AMAP_KEY } from '@/configs';
import { ConfigContext } from '@/utils/context';
import styles from './index.module.less';

const positions = {
  BJ: [116.33003, 39.99228],
  SZ: [113.952667, 22.541702],
  HK: [114.213463, 22.426231],
};

export default function MapContainer() {
  const { language } = useContext(ConfigContext);

  const handleLoadMap = async () => {
    try {
      const AMap = await AMapLoader.load({
        key: REACT_APP_AMAP_KEY as string,
        version: '2.0',
        plugins: [],
      });
      const map = new AMap.Map('map-container', {
        resizeEnable: true,
        center: positions.BJ,
        zoom: 3,
      });
      map.setMapStyle('amap://styles/darkblue');

      const markers = Object.entries(positions).map(([name, position]) => {
        const marker = new AMap.Marker({
          position: new AMap.LngLat(...position),
          title: name,
          icon: '//webapi.amap.com/theme/v1.3/markers/n/mark_bs.png',
        });
        return marker;
      });
      map.add(markers);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (language === 'zh') {
      handleLoadMap();
    }
  }, [language]);

  return (
    <div
      id="map-container"
      style={{ height: '40vh' }}
      className={styles.container}
    ></div>
  );
}
