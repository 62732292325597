import { forwardRef, PropsWithChildren } from 'react';
import styles from './index.module.less';

const ShadowCard = forwardRef(
  ({ children, className, ...rest }: PropsWithChildren<any>, ref) => {
    return (
      <section
        className={`${styles.card} ${styles.className}`}
        ref={ref}
        {...rest}
      >
        {children}
      </section>
    );
  }
);

export default ShadowCard;
