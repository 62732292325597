import { LocationOutlined } from '@/components/Icon';
import MapContainer from '@/components/MapContainer';
import { useBaseInfo } from '@/configs/info';
import { REACT_APP_ASSETS_URL } from '@/configs';
import { t, Trans } from '@lingui/macro';
import { Typography, Image, Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { OverPack } from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.less';
import { useContext } from 'react';
import { ConfigContext } from '@/utils/context';

export default function JoinUs() {
  const navigate = useNavigate();
  const baseInfo = useBaseInfo();
  const { language } = useContext(ConfigContext);

  if (language === 'en') {
    return (
      <OverPack id="join-us" playScale={0.15}>
        <TweenOne
          key="join-us-header"
          animation={{ opacity: 1 }}
          style={{
            opacity: 0,
            textAlign: 'center',
          }}
        >
          <Typography.Title level={3}>
            <Trans id="home.join-us">加入我们</Trans>
          </Typography.Title>
        </TweenOne>
        <TweenOne
          key="join-us-img"
          animation={{ opacity: 1 }}
          style={{
            opacity: 0,
            textAlign: 'center',
            marginBottom: '2.5em',
          }}
        >
          <div
            className={styles.joinUsImg}
            style={{
              background: `linear-gradient(
              0deg,
              rgba(30, 75, 245, 0.7),
              rgba(30, 75, 245, 0.7)
            ),
            url(${REACT_APP_ASSETS_URL}/banner/Banner-24.jpg)`,
            }}
          >
            <div>
              <p>
                At Kasma, we champion a culture that fosters impactful
                innovation, unwavering pursuit of excellence, and inclusivity.
                We cultivate an environment where ideas can freely percolate and
                open dialogues are encouraged. Collaboration forms the backbone
                of our team, promoting synergy and cohesion.
              </p>
              <p>
                Join us on this exciting journey as we carve a path for data
                innovation and AI-driven transformation.Together, we can unlock
                new opportunities and drive meaningful change in the digital
                landscape.
              </p>
            </div>
            <Button size="large" onClick={() => navigate('/recruit')}>
              {t`home.join-button-text`}
            </Button>
          </div>
        </TweenOne>
      </OverPack>
    );
  }

  return (
    <OverPack id="join-us" playScale={0.15}>
      <TweenOne
        key="join-us-header"
        animation={{ opacity: 1 }}
        style={{
          opacity: 0,
          textAlign: 'center',
          marginBottom: '2.5em',
        }}
      >
        <Typography.Title level={3}>
          <Trans id="home.join-us">加入我们</Trans>
        </Typography.Title>
        <Typography.Text>
          <Trans id="home.office">我们已经在香港、北京、深圳设有 Office</Trans>
        </Typography.Text>
      </TweenOne>
      <MapContainer />
      <QueueAnim
        key="core-team-items"
        leaveReverse
        type="bottom"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
          gap: '3em',
        }}
      >
        {baseInfo.map(({ name, img, base }) => (
          <section key={name} className={styles.joinUsItem}>
            <Image
              style={{ minWidth: 320 }}
              src={img}
              placeholder={
                <section
                  style={{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                ></section>
              }
            />
            <header>
              <LocationOutlined />
              <span>{base}</span>
            </header>
            <span>{name}</span>
          </section>
        ))}
      </QueueAnim>
      <TweenOne
        key="join-us-img"
        animation={{ opacity: 1 }}
        style={{
          opacity: 0,
          textAlign: 'center',
          marginBottom: '2.5em',
        }}
      >
        <div
          className={styles.joinUsImg}
          style={{
            padding: '5em',
          }}
        >
          <Button size="large" onClick={() => navigate('/recruit')}>
            {t`home.join-button-text`}
          </Button>
        </div>
      </TweenOne>
    </OverPack>
  );
}
