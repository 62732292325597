import { useEffect, useContext, useState } from 'react';
import { setupI18n } from '@lingui/core';
import { LOCALE_LANGUAGE } from '@/configs/constants';
import { ConfigContext } from '@/utils/context';
import { useLocalStorage } from '@/hooks';
import { antdI18nMap } from '@/configs';
import { SupportedLocales } from '@/typings';
import { getDefaultLangFromURL } from '@/utils';
import { ConfigProviderProps } from 'antd/lib/config-provider';
import { en, zh } from 'make-plural/plurals';

export const i18n = setupI18n({
  localeData: {
    en: { plurals: en },
    zh: { plurals: zh },
  },
});

const useIntlProvider = () => {
  const { language: defaultLocale } = useContext(ConfigContext);
  const languageFromURL = getDefaultLangFromURL();
  const [language, setLanguage] = useLocalStorage<SupportedLocales>(
    LOCALE_LANGUAGE,
    defaultLocale
  );
  const currentLanguage = (languageFromURL || language) as SupportedLocales;

  const [locale, setLocale] = useState<ConfigProviderProps['locale']>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    import(`@/locales/${currentLanguage}/messages.po`).then((data) => {
      i18n.load(currentLanguage, data.messages);
      i18n.activate(currentLanguage);
      setLoading(false);
    });

    const path = antdI18nMap[currentLanguage];
    import(`antd/lib/locale/${path}.js`).then((data) => {
      setLocale(data.default);
    });
  }, [currentLanguage]);

  return {
    language: currentLanguage,
    setLanguage,
    locale,
    i18n,
    loading,
  };
};

export default useIntlProvider;
