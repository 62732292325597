import QueueAnim from 'rc-queue-anim';
import { Trans } from '@lingui/macro';
import { REACT_APP_ASSETS_URL } from '@/configs';
import BgContainer from '@/components/BgContainer';
import AboutUs from './AboutUs';
import Culture from './Culture';
import CoreTeam from './CoreTeam';
import JoinUs from './JoinUs';
import styles from './index.module.less';
import './index.less';
import { useContext } from 'react';
import { ConfigContext } from '@/utils/context';
import Product from './Product';

const Home = () => {
  const { language } = useContext(ConfigContext);
  return (
    <>
      <div id="home" />
      <BgContainer
        headerBgImg={`${REACT_APP_ASSETS_URL}/banner/Banner-22.jpg`}
        header={
          <QueueAnim duration={1000}>
            <section key="home" className={styles.title}>
              <Trans id="home.title">
                <header>Graxy</header>
                <span>致力于开发云原生的</span>
                <span>
                  一站式图数据 <strong>存储、计算、训练</strong> 平台
                </span>
              </Trans>
            </section>
          </QueueAnim>
        }
      >
        <AboutUs />
        <QueueAnim
          delay={500}
          duration={1000}
          animConfig={[
            { opacity: [1, 0], translateY: [0, 50] },
            { opacity: [1, 0], translateY: [0, -50] },
          ]}
        >
          <section key={'intro'} className={styles.contentContainer}>
            <Culture />
            <div style={{ display: language !== 'zh' ? 'none' : 'block' }}>
              <CoreTeam />
            </div>
            <div style={{ display: language === 'zh' ? 'none' : 'block' }}>
              <Product />
            </div>
            <JoinUs />
          </section>
        </QueueAnim>
      </BgContainer>
    </>
  );
};

export default Home;
