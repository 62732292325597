import { Image, Typography } from 'antd';
import { OverPack } from 'rc-scroll-anim';
import { REACT_APP_ASSETS_URL } from '@/configs';
import styles from './index.module.less';
import TweenOne from 'rc-tween-one';

export default function Product() {
  return (
    <OverPack id="product" playScale={0.15}>
      <TweenOne
        key="product-content"
        animation={{ opacity: 1 }}
        style={{
          opacity: 0,
          textAlign: 'center',
        }}
      >
        <div className={styles.product} id="flagship-product">
          <div>
            <Typography.Title level={3} style={{ textAlign: 'center' }}>
              {`Flagship Product`}
            </Typography.Title>
            <p>
              Graxy, a cloud-native one-stop platform for graph data management,
              graph computing and graph machine learning
            </p>
          </div>
          <div className={styles.productContent}>
            <div>
              <Image
                src={`${REACT_APP_ASSETS_URL}/banner/Flagship-Product.jpg`}
              />
              <div>
                <p>
                  At Kasma, we are currently building a revolutionary graph data
                  platform called Graxy. Leveraging the latest advancements in
                  graph AI and streaming graph analytics, Graxy promises
                  peerless performance across a wide range of critical
                  applications including recommendation systems, risk
                  management, customer 360, life sciences, and the emerging
                  realm of Web 3.0.
                </p>
                <p>
                  What distinguishes Graxy is its comprehensive suite of
                  features, each carefully designed and engineered to cater to
                  the core needs of businesses. As a result, organizations can
                  fully exploit their graph data, thereby maximizing the value
                  they derive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </TweenOne>
    </OverPack>
  );
}
