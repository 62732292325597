import { Route } from '@/typings/router';
import { ConfigContext } from '@/utils/context';
import { t } from '@lingui/macro';
import { useContext } from 'react';

export const useMenus = () => {
  const { language } = useContext(ConfigContext);
  const zh: Route[] = [
    {
      path: '/#about-us',
      name: t`关于我们`,
      key: 'about-us',
    },
    {
      path: '/#core-team',
      name: t`核心团队`,
      key: 'core-team',
    },
    {
      path: '/#join-us',
      name: t`加入我们`,
      key: 'join-us',
    },
    {
      path: '/recruit',
      name: t`职位招聘`,
      key: 'recruit',
    },
  ];
  const en = [
    {
      path: '/#about-us',
      name: t`关于我们`,
      key: 'about-us',
    },
    {
      path: '/#flagship-product',
      name: `Flagship Product`,
      key: 'flagship-product',
    },
    {
      path: '/#join-us',
      name: t`加入我们`,
      key: 'join-us',
    },
    {
      path: '/recruit',
      name: t`职位招聘`,
      key: 'recruit',
    },
  ];
  return {
    zh,
    en,
  }[language];
};

export default useMenus;
