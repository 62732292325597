import { Typography } from 'antd';
import { t } from '@lingui/macro';
import List from '@/components/AnimateList';
import { TRecruitItem } from '@/typings/recruit';
import styles from './index.module.less';

interface IProps {
  list: TRecruitItem[] | null;
  total: number;
}
const RecruitContent = ({ list, total }: IProps) => {
  if (!list) {
    return <></>;
  }
  return (
    <section className={styles.content}>
      <Typography.Title level={5}>
        {t({
          id: 'recruit.total',
          message: '共计 {total} 个符合条件的岗位',
          values: {
            total,
          },
        })}
      </Typography.Title>
      <List itemHeight={136} data={list} />
    </section>
  );
};

export default RecruitContent;
