import { useContext, useEffect, useState } from 'react';
import QueueAnim from 'rc-queue-anim';
import BgContainer from '@/components/BgContainer';
import Notification from '@/components/Notification';
import { getRecruitList } from '@/server';
import { RecruitOptionsType, TRecruitItem } from '@/typings/recruit';
import RecruitOptions from './Options';
import RecruitTitle from './Title';
import RecruitContent from './Content';
import styles from './index.module.less';
import { REACT_APP_ASSETS_URL } from '@/configs/default';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { ConfigContext } from '@/utils/context';

const Recruit = () => {
  const [total, setTotal] = useState<number>(0);
  const [list = [], setList] = useState<TRecruitItem[]>([]);
  const [options, setOptions] = useState<RecruitOptionsType>();
  const { language: locale } = useContext(ConfigContext);
  const { i18n } = useLingui();

  const data = list.map((item) => ({
    ...item,
    name: i18n._(item?.name || ''),
  }));

  const getList = async () => {
    try {
      const { data, total } = await getRecruitList({
        ...options,
        languages: locale,
      });
      setTotal(total);
      setList(data.list);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <BgContainer
      headerBgImg={`${REACT_APP_ASSETS_URL}/banner/Banner-23.jpg`}
      header={
        <RecruitTitle
          info={
            <p>
              <Trans id="recruit.intro-title">
                我们正在搭建核心初创团队，
                <br />
                欢迎有<strong> 分布式存储系统、分布式计算系统、AI 系统 </strong>
                等底层系统开发经验的工程师加入我们！
                <br />
                你会跟一帮优秀且富有激情的同事一起成长，共同实现前沿且有创造性的工作！
              </Trans>
            </p>
          }
        />
      }
    >
      <QueueAnim delay={700} duration={1000} type="bottom">
        {locale === 'zh' ? (
          <section key="notification">
            <Notification>{t`recruit.methods`}</Notification>
          </section>
        ) : (
          <div />
        )}
        <section key="content-container" className={styles.contentContainer}>
          <RecruitContent key="zh" list={data as any} total={total} />
          {locale === 'zh' ? (
            <RecruitOptions
              onSubmit={(item: RecruitOptionsType) => setOptions(item)}
            />
          ) : (
            <div />
          )}
        </section>
      </QueueAnim>
    </BgContainer>
  );
};

export default Recruit;
