import { useTeamInfo } from '@/configs/info';
import { t } from '@lingui/macro';
import { Image, Skeleton, Typography } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { OverPack } from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import styles from './index.module.less';

export default function CoreTeam() {
  const teamInfo = useTeamInfo();

  return (
    <OverPack playScale={0.15} id="core-team">
      <TweenOne
        key="core-team-header"
        animation={{ opacity: 1 }}
        style={{ opacity: 0, textAlign: 'center' }}
      >
        <Typography.Title level={3}>{t`home.core-team`}</Typography.Title>
      </TweenOne>
      <QueueAnim
        key="core-team-items"
        leaveReverse
        type="bottom"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
          gap: '3em',
          marginTop: '4em',
        }}
      >
        {teamInfo.map(({ name, avatar, title, intro }) => (
          <section key={name} className={styles.coreTeamItem}>
            <Image
              src={avatar}
              placeholder={
                <section
                  style={{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton.Avatar active size={100} />
                </section>
              }
              width={100}
              height={100}
              style={{
                borderRadius: '50%',
              }}
              preview={false}
            />
            <header>{name}</header>
            <span>{title}</span>
            <section>{intro}</section>
          </section>
        ))}
      </QueueAnim>
    </OverPack>
  );
}
