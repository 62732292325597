//import QueueAnim from 'rc-queue-anim';
import { PropsWithChildren, ReactElement, useEffect } from 'react';
import styles from './index.module.less';
import { useLocation } from 'react-router-dom';
import { REACT_APP_ASSETS_URL } from '@/configs';

interface IProps {
  header: ReactElement;
  headerBgImg?: string;
}
const BgContainer = ({
  children,
  header,
  headerBgImg = REACT_APP_ASSETS_URL + '/banner/Banner-22.jpg',
  ...rest
}: PropsWithChildren<IProps>) => {
  const location = useLocation();
  useEffect(() => {
    if (document.documentElement || document.body) {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    }
  }, [location.pathname]);

  return (
    <section {...rest}>
      <section
        className={styles.title}
        style={{
          backgroundImage: `url(${headerBgImg})`,
        }}
      >
        <div>{header}</div>
      </section>
      <section className={styles.content}>{children}</section>
      <section className={styles.footer}>
        <span>© 2023 Kasma</span>
        <span>Email: recruit@kasma.ai</span>
      </section>
    </section>
  );
};

export default BgContainer;
