import { CSSProperties, useEffect, useState } from 'react';
import { Button, Checkbox, Drawer, Typography } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useMediaQuery } from 'react-responsive';
import { t } from '@lingui/macro';
import styles from './index.module.less';
import {
  RecruitBaseType,
  RecruitJobType,
  RecruitProjectType,
  RecruitType,
} from '@/typings/recruit';
import { getRecruitOptions } from '@/server';
import { useRecruitMap } from './constant';

const CheckboxGroup = Checkbox.Group;
export enum RecruitOptionsKeyType {
  JOB,
  TYPE,
  PROJECT,
  BASE,
}

const style: CSSProperties = {
  display: 'grid',
  gap: '0.725em',
  marginTop: '8px',
  marginBottom: '24px',
};

// todo：适配窄屏，收起为选择框
const Option = ({ options, name, checkedList, setCheckedList }) => {
  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  return (
    <>
      <Typography.Title level={5}>{name}</Typography.Title>
      <CheckboxGroup
        className={styles.checkbox}
        options={options}
        defaultValue={checkedList}
        onChange={onChange}
        style={style}
      />
    </>
  );
};
const RecruitOptions = ({ onSubmit }) => {
  const [jobCheckedList, setJobCheckedList] = useState<RecruitJobType[]>([]);
  const [typeCheckedList, setTypeCheckedList] = useState<RecruitType[]>([]);
  const [projectCheckedList, setProjectCheckedList] = useState<
    RecruitProjectType[]
  >([]);
  const [baseCheckedList, setBaseCheckedList] = useState<RecruitBaseType[]>([]);
  const [list, setList] = useState<any[]>([]);
  const {
    RecruitJobTypeName,
    RecruitTypeName,
    RecruitProjectTypeName,
    RecruitBaseTypeName,
    RecruitOptionTypeName,
  } = useRecruitMap();

  const format = (item, map) => {
    item.options = item.options.map((item) => ({
      value: item,
      label: map[item],
    }));
    item.name = RecruitOptionTypeName[item.type];
    return item;
  };

  const getOptions = async () => {
    try {
      const { data: list } = await getRecruitOptions();
      setList([
        format(list[0], RecruitJobTypeName),
        format(list[1], RecruitTypeName),
        format(list[2], RecruitProjectTypeName),
        format(list[3], RecruitBaseTypeName),
      ]);
    } catch (e) {}
  };
  const handleSubmit = () => {
    onSubmit({
      job: jobCheckedList,
      type: typeCheckedList,
      project: projectCheckedList,
      base: baseCheckedList,
    });
  };

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobCheckedList, typeCheckedList, projectCheckedList, baseCheckedList]);

  return (
    <section key="recruit-options" className={styles.optionsContainer}>
      <Option
        {...list[0]}
        checkedList={jobCheckedList}
        setCheckedList={setJobCheckedList}
      />
      <Option
        {...list[1]}
        checkedList={typeCheckedList}
        setCheckedList={setTypeCheckedList}
      />
      <Option
        {...list[2]}
        checkedList={projectCheckedList}
        setCheckedList={setProjectCheckedList}
      />
      <Option
        {...list[3]}
        checkedList={baseCheckedList}
        setCheckedList={setBaseCheckedList}
      />
    </section>
  );
};

const RecruitOptionsContainer = ({ onSubmit }) => {
  const isWideScreen = useMediaQuery({ minWidth: 768 });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  if (isWideScreen) {
    return <RecruitOptions onSubmit={onSubmit} />;
  }
  return (
    <>
      <Button type="primary" ghost onClick={() => setIsDrawerOpen(true)}>
        {t`recruit.options-title`}
      </Button>
      <Drawer
        title={t`recruit.options-title`}
        placement="right"
        width={200}
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
      >
        <RecruitOptions onSubmit={onSubmit} />
      </Drawer>
    </>
  );
};

export default RecruitOptionsContainer;
