import React, { useState, useEffect } from 'react';
import { useTransition, animated } from '@react-spring/web';
import { Link } from 'react-router-dom';
import { useRecruitMap } from '@/pages/Recruit/constant';
import ShadowCard from '../ShadowCard';
import styles from './index.module.less';

function List({ data, itemHeight }) {
  const [rows, set] = useState(data);
  const { RecruitBaseTypeName, RecruitTypeName } = useRecruitMap();
  useEffect(() => {
    set(data);
  }, [data]);

  let height = 0;
  const transitions = useTransition(
    rows.map((data) => ({ ...data, y: (height += itemHeight) - itemHeight })),
    {
      key: (item: any) => item.id,
      from: { height: 0, opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: ({ y, height }) => ({ y, height, opacity: 1 }),
      update: ({ y, height }) => ({ y, height }),
    }
  );

  return (
    <div style={{ height }}>
      {transitions((style, item, t, index) => (
        <animated.div
          style={{
            zIndex: data.length - index,
            ...style,
          }}
        >
          <Link key={item.name} to={`/recruit/${item.id}`}>
            <ShadowCard key={item.name}>
              <section className={styles.item}>
                <header>{item.name}</header>
                <section>
                  {[
                    item.type?.map((key) => RecruitTypeName[key]).join(`  ·  `),
                    item.base
                      ?.map((key) => RecruitBaseTypeName[key])
                      .join(`  ·  `),
                  ]
                    .filter((item) => {
                      return item;
                    })
                    .join(`  /  `)}
                </section>
              </section>
            </ShadowCard>
          </Link>
        </animated.div>
      ))}
    </div>
  );
}

export default List;
