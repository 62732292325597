import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QueueAnim from 'rc-queue-anim';
import BgContainer from '@/components/BgContainer';
import SubHeader from '@/components/SubHeader';
import Notification from '@/components/Notification';
import { getRecruitDetail } from '@/server';
import { TRecruitItem, RecruitProjectType } from '@/typings/recruit';
import { REACT_APP_ASSETS_URL } from '@/configs/default';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import RecruitTitle from './Title';
import styles from './index.module.less';
import { useRecruitMap } from './constant';
import { ConfigContext } from '@/utils/context';

const RecruitDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [info, setInfo] = useState<TRecruitItem>();
  const {
    RecruitJobTypeName,
    RecruitTypeName,
    RecruitProjectTypeName,
    RecruitBaseTypeName,
  } = useRecruitMap();
  const { i18n } = useLingui();
  const { language } = useContext(ConfigContext);

  const title = info ? (
    <section className={styles.detailTitle}>
      <span>{info.name}</span>
      <span>
        {[
          RecruitJobTypeName[info.job as any],
          info.type?.map((item) => RecruitTypeName[item]).join(`  ·  `),
          RecruitProjectTypeName[info.project ?? RecruitProjectType.GRAXY],
          info.base?.map((item) => RecruitBaseTypeName[item]).join(`  ·  `),
        ]
          .filter((item) => item)
          .join(`  /  `)}
      </span>
    </section>
  ) : null;

  const showDetail = (name, prop: keyof TRecruitItem) =>
    info && info[prop] && info[prop]?.length !== 0 ? (
      <section className={styles.detailSection}>
        <SubHeader>{name}</SubHeader>
        {Array.isArray(info[prop]) ? (
          (info[prop] as any[]).map((item) => <li key={item}>{item}</li>)
        ) : (
          <section>{info[prop]}</section>
        )}
      </section>
    ) : (
      <></>
    );

  const getDetail = async (keyword) => {
    const info = await getRecruitDetail(keyword, language);
    if (!info) {
      navigate('/404');
    }

    setInfo({
      ...info,
      extra: info?.extra?.map((item) => i18n._(item)),
      requirement: info?.requirement?.map((item) => i18n._(item)),
      duty: info?.duty?.map((item) => i18n._(item)),
      name: i18n._(info?.name || ''),
    });
  };
  useEffect(() => {
    const id = location.pathname.split('/').pop();
    if (id) {
      const keyword = decodeURIComponent(id);
      getDetail(keyword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!info) {
    return (
      <BgContainer
        headerBgImg={`${REACT_APP_ASSETS_URL}/banner/Banner-23.jpg`}
        header={<section>loading...</section>}
      >
        <section
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <></>
        </section>
      </BgContainer>
    );
  }

  return (
    <BgContainer
      headerBgImg={`${REACT_APP_ASSETS_URL}/banner/Banner-23.jpg`}
      header={<RecruitTitle info={title} />}
    >
      <QueueAnim
        duration={1000}
        delay={600}
        style={{
          paddingTop: language === 'en' ? '3em' : '0em',
        }}
      >
        <section key="notification">
          {language === 'zh' ? (
            <Notification>
              <Trans id="recruit.methods">
                投递方式：Email: recruit@kasma.ai，联系时请备注: 姓名+岗位
              </Trans>
            </Notification>
          ) : (
            <span />
          )}
        </section>
        <section key="recruit-detail" className={styles.infoContainer}>
          <section>{info.summary}</section>
          {showDetail(t`recruit.options-detail-duty`, 'duty')}
          {showDetail(t`recruit.options-detail-requirement`, 'requirement')}
          {showDetail(t`recruit.options-detail-extra`, 'extra')}
          {showDetail(t`recruit.options-detail-work-mode`, 'workMode')}
        </section>
        {language === 'en' ? (
          <div className={styles.notificationInfo} key="notification-info">
            <p>
              We offer competitive compensation packages and a dynamic work
              environment that encourages creativity and collaboration. If you
              are excited about the opportunity to work on cutting edge
              technology and are looking for a challenging and rewarding career,
              we want to hear from you!
            </p>
            <p>
              To apply, please submit your resume and cover letter to
              <a href="#"> recruit@kasma.ai</a>
            </p>
          </div>
        ) : (
          <span />
        )}
      </QueueAnim>
    </BgContainer>
  );
};

export default RecruitDetail;
