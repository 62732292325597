import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './index.module.less';

const SubHeader: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  ...rest
}: PropsWithChildren<any>) => {
  return (
    <section className={styles.container} {...rest}>
      <span>{children}</span>
    </section>
  );
};

export default SubHeader;
